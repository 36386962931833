import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, child, get, set, remove, update } from "firebase/database";
import { getFirestore, collection, getDoc, doc, updateDoc } from 'firebase/firestore';
import React, { useState, useEffect } from "react";
import OthelloMultiplayer from '../Multiplayer/OthelloMultiplayer';
import './GamePage.css'
import { retrieveGameDetails, setGameDetails, setGameID, setUserId } from '../Utils/StorageUtils';
import { Box, Modal, Typography } from '@mui/material';

const GamePage = () => {
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [players, setPlayers] = useState([]);
    const [isHost, setIsHost] = useState(false);
    const [currentPlayer, setCurrentPlayer] = useState(null);
    const [userName, setUserName] = useState(null);
    const [playerId, setPlayerId] = useState(null);
    const [registerPlayer, setRegisterPlayer] = useState(false);
    var x = Array(8).fill(0).map(x => Array(8).fill(0));
    x[3][3] = "W";
    x[3][4] = "B";
    x[4][3] = "B";
    x[4][4] = "W";

    const [trueBoard, setTrueBoard] = useState(null);
    const [whitePoints, setWhitePoints] = useState(null);
    const [blackPoints, setBlackPoints] = useState(null);

    const location = useLocation();
    const isSpectator = playerId === 0;
    const gameCode = location.pathname.substr(1, location.pathname.length);
    const navigate = useNavigate();

    const firebaseConfig = {
        apiKey: "AIzaSyDxU5FfXzCWi284vAWYSEPvUj33E5RcEn0",
        authDomain: "spyfall-21cd3.firebaseapp.com",
        databaseURL: "https://spyfall-21cd3-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "spyfall-21cd3",
        storageBucket: "spyfall-21cd3.appspot.com",
        messagingSenderId: "49369218630",
        appId: "1:49369218630:web:d4e7157e653eace3ce5447",
        measurementId: "G-WW7LCN4LZH"
    };

    const app = initializeApp(firebaseConfig);
    const rdb = getDatabase(app);
    const fdb = getFirestore(app);
    let { id } = useParams();

    useEffect(() => {
        getGameInfo(rdb);
        if (location.state && location.state.isHost) {
            setIsHost(true);
        }

        setup(rdb);
    }, []);

    useEffect(() => {
        updateBoard(rdb, trueBoard);
    }, [trueBoard]);

    async function updateBoard(db, newBoardState) {
        if (!newBoardState) return;
        const updates = {}
        updates['/Games/' + gameCode + '/Board'] = newBoardState;
        update(ref(db), updates);
    }

    useEffect(() => {
        updateCurrentplayer(rdb, currentPlayer);
    }, [currentPlayer]);

    async function updateCurrentplayer(db, newPlayer) {
        if (!newPlayer) return;
        const updates = {}
        updates['/Games/' + gameCode + '/CurrentPlayer'] = newPlayer;
        update(ref(db), updates);
    }


    useEffect(() => {
        updateBlackPoints(rdb, blackPoints);
    }, [blackPoints]);

    async function updateBlackPoints(db, newblackPoints) {
        if (!newblackPoints) return;
        const updates = {}
        updates['/Games/' + gameCode + '/BlackPoints'] = newblackPoints;
        update(ref(db), updates);
    }

    useEffect(() => {
        updateWhitePoints(rdb, whitePoints);
    }, [whitePoints]);

    async function updateWhitePoints(db, newWhitePoints) {
        if (!newWhitePoints) return;
        const updates = {}
        updates['/Games/' + gameCode + '/WhitePoints'] = newWhitePoints;
        update(ref(db), updates);
    }

    async function getGameInfo(db) {
        setLoading(true);
        const starCountRef = ref(db, `Games/${gameCode}`);
        onValue(starCountRef, (snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val());
                setPlayers(snapshot.val().Players);

                if (snapshot.val().Board === undefined) {
                    setTrueBoard(x);
                    setLoading(false);
                }
                else {
                    setTrueBoard(snapshot.val().Board);
                    setLoading(false);
                }

                if (snapshot.val().CurrentPlayer === undefined) {
                    setCurrentPlayer("B");
                }
                else {
                    setCurrentPlayer(snapshot.val().CurrentPlayer);
                }

                if (snapshot.val().WhitePoints === undefined) {
                    setWhitePoints(2);
                }
                else {
                    setWhitePoints(snapshot.val().WhitePoints);
                }

                if (snapshot.val().BlackPoints === undefined) {
                    setBlackPoints(2);
                }
                else {
                    setBlackPoints(snapshot.val().BlackPoints);
                }
            }
            else {
                setErrorMessage(`Game ${gameCode} does not exist.`);
                setLoading(false)
            }
        });
    }

    async function handleEndGame() {
        await DetachGameID();
        await KillGameProcessOnRDB();
        RedirectUserToGamePage("");
    }

    function KillGameProcessOnRDB() {
        remove(ref(rdb, 'Games/' + gameCode));
    }

    async function DetachGameID() {
        var gameIDs = { Assigned: null };

        const assignedDocRef = doc(fdb, "GameIDs", "Assigned");
        const assignedDocSnap = await getDoc(assignedDocRef);

        if (assignedDocSnap.exists()) {
            gameIDs.Assigned = assignedDocSnap.data().GameIDs
        } else {
            console.log("One of the docs dont exist");
            return;
        }

        var gameIDIndex = gameIDs.Assigned.indexOf(gameCode);

        if (gameIDIndex > -1) {
            gameIDs.Assigned.splice(gameIDIndex, 1);
            const assignedRef = doc(fdb, 'GameIDs', 'Assigned');

            await updateDoc(assignedRef, { GameIDs: gameIDs.Assigned });
        }
    }

    function RedirectUserToGamePage(gameID) {
        navigate(`/${gameID}`);
    }

    function onPlayerMoved() {
        if (currentPlayer === "W") {
            setCurrentPlayer("B");
        }
        else {
            setCurrentPlayer("W");
        }
    }

    function setup(db) {
        const tempUsername = location.state && location.state.userName && location.state.userName.length > 0 ? location.state.userName : null;
        setUserName(tempUsername);

        const starCountRef = ref(db, `Games/${gameCode}`);
        onValue(starCountRef, (snapshot) => {
            if (snapshot.exists()) {
                const playersTemp = snapshot.val().Players;
                setRegisterPlayer((playersTemp && playersTemp.length < 2) && tempUsername === null ? true : false);

                if (tempUsername) {
                    setGameDetails(gameCode, tempUsername);
                    if (playersTemp[0] === tempUsername) {
                        setPlayerId(1);
                    }
                    else if (playersTemp.length === 2 && playersTemp[1] === tempUsername) {
                        setPlayerId(2);
                    }
                }
                else {
                    const gameDetails = retrieveGameDetails(gameCode);
                    console.log(gameDetails);
                    if (gameDetails && playersTemp.includes(gameDetails)) {
                        setUserName(gameDetails);

                        if (playersTemp[0] === gameDetails) {
                            setPlayerId(1);
                        }
                        else if (playersTemp.length === 2 && playersTemp[1] === gameDetails) {
                            setPlayerId(2);
                        }
                    }
                    else {
                        console.log(`${gameDetails
                            } not in players: ${playersTemp}`)
                    }
                }
            }
            else {
                setErrorMessage(`Game ${gameCode} does not exist.`);
            }
        });
    }

    const createNewPlayer = (e) => {
        if (!userName || userName.length === 0) {
            alert("Please enter a username");
            return;
        }

        var playerList = []
        const starCountRef = ref(rdb, `Games/${gameCode}`);
        onValue(starCountRef, (snapshot) => {
            if (snapshot.exists()) {
                playerList = snapshot.val().Players;
            }
        });

        playerList.push(userName);
        const updates = {}
        updates['/Games/' + gameCode + '/Players'] = playerList;
        update(ref(rdb), updates);
        setPlayerId(2);
    }

    return (
        <div>
            <div>
                {
                    loading ? <h1>Loading...</h1> :
                        errorMessage ?
                            <h1>{errorMessage}</h1> :
                            <div>
                                <Modal
                                    className='set-username-cont'
                                    open={registerPlayer}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box
                                        className='landing-page-container center-div flex-col'>
                                        <div className='lobby-code'>
                                            Set Username
                                        </div>
                                        <input className='text-input' placeholder='Username' onChange={e => setUserName(e.target.value)}></input>
                                        <button onClick={createNewPlayer} className="clickable base-button base-text create-bt">Set</button>
                                    </Box>
                                </Modal>
                                <OthelloMultiplayer
                                    currentPlayer={currentPlayer}
                                    playerColour={playerId === 1 ? "B" : playerId === 2 ? "W" : "S"}
                                    whitePoints={whitePoints}
                                    blackPoints={blackPoints}
                                    trueBoard={trueBoard}
                                    gameCode={gameCode}
                                    players={players}
                                    playerId={playerId}
                                    isHost={isHost}
                                    userName={userName}
                                    handleEndGame={handleEndGame}
                                    onPlayerMoved={onPlayerMoved}
                                    setTrueBoard={setTrueBoard}
                                    setWhitePoints={setWhitePoints}
                                    setBlackPoints={setBlackPoints} />
                            </div>
                }
            </div>
        </div>
    )
}

export default GamePage
