import './LandingPage.css';
import React, { useState, useEffect } from "react";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, child, get, set, update } from "firebase/database";
import { getFirestore, collection, getDoc, doc, updateDoc } from 'firebase/firestore';
import { BrowserRouter as Router, Route, Link, useNavigate, Redirect } from "react-router-dom";

function LandingPage() {
	const [games, setGames] = useState([]);
	const [loading, setLoading] = useState(false);
	const [gameState, setGameState] = useState(null);
	const [gameCode, setGameCode] = useState(null);
	const [targetGame, setTargetGame] = useState(null);
	const [userName, setUserName] = useState(null);
	const navigate = useNavigate();

	const codeChangeHandler = ({ target }) => {
		const code = target.value.toUpperCase()
		setGameCode(code);

		if (code.length === 4) {
			console.log("Do check");
			checkCode(code);
		}
		else {
			console.log("No check")
			setTargetGame(null);
			setGameState(null);
		}
	}

	const checkCode = (code) => {
		const dbRef = ref(getDatabase());

		get(child(dbRef, `Games/${code}`)).then((snapshot) => {
			if (snapshot.exists()) {
				console.log(snapshot.val());
				if (snapshot.val().Players.length === 2) {
					setGameState(`Game: ${snapshot.val().GameID} is full.`);
				}
				else {
					setGameState(`Game: ${snapshot.val().GameID} is ${snapshot.val().Status}`);
				}
				setTargetGame(code);
			} else {
				setGameState(`Game: ${code} does not exist.`);
				setTargetGame(null);
			}
		}).catch((error) => {
			console.error(error);
		});
	}

	const onSubmit = (e) => {
		e.preventDefault();

		if (targetGame) {
			var playerList = []
			const starCountRef = ref(rdb, `Games/${targetGame}`);
			onValue(starCountRef, (snapshot) => {
				if (snapshot.exists()) {
					playerList = snapshot.val().Players;
				}
			});

			playerList.push(userName);
			const updates = {}
			updates['/Games/' + targetGame + '/Players'] = playerList;
			update(ref(rdb), updates);

			RedirectUserToGamePage(targetGame, false, userName);
		}
	}

	const firebaseConfig = {
		apiKey: "AIzaSyDxU5FfXzCWi284vAWYSEPvUj33E5RcEn0",
		authDomain: "spyfall-21cd3.firebaseapp.com",
		databaseURL: "https://spyfall-21cd3-default-rtdb.asia-southeast1.firebasedatabase.app",
		projectId: "spyfall-21cd3",
		storageBucket: "spyfall-21cd3.appspot.com",
		messagingSenderId: "49369218630",
		appId: "1:49369218630:web:d4e7157e653eace3ce5447",
		measurementId: "G-WW7LCN4LZH"
	};

	const app = initializeApp(firebaseConfig);
	const rdb = getDatabase(app);
	const fdb = getFirestore(app);

	useEffect(() => {
		getGames(rdb);
	}, [])

	function getGames(db) {
		setLoading(true);
		const starCountRef = ref(db, 'Games');
		onValue(starCountRef, (snapshot) => {
			const items = []
			snapshot.forEach(function (child) { items.push(child.val()) });
			setGames(items);
			setLoading(false)
		});
	}

	async function onCreateNewGame() {
		if (!userName || userName.length === 0) {
			setGameState("Please enter a username.");
			return;
		}

		const gameID = await AssignNewGameID();
		if (gameID) {
			CommitToRDB(gameID, [userName]);
			RedirectUserToGamePage(gameID, true, userName);
		}
	}

	function createLocalGame() {
		navigate('/local');
	}

	function RedirectUserToGamePage(gameID, isHost, userName) {
		navigate(`/${gameID}`, { state: { detail: 'Landing', isHost: isHost, userName: userName } });
	}

	function CommitToRDB(gameID, players) {
		set(ref(rdb, 'Games/' + gameID), {
			GameID: gameID,
			Status: "Running",
			Players: players
		});
	}

	async function AssignNewGameID() {
		const assignedDocRef = doc(fdb, "GameIDs", "Assigned");
		const assignedDocSnap = await getDoc(assignedDocRef);
		var gameIDs = null;
		var generatedGameID = null;

		if (assignedDocSnap.exists()) {
			generatedGameID = createNewGameID();
			gameIDs = assignedDocSnap.data().GameIDs // assigned game ids

			if (gameIDs.length > 0) {
				while (gameIDs.includes(generatedGameID)) {
					console.log(`GameID ${generatedGameID} exists`);
					generatedGameID = createNewGameID();
				}
			}

			gameIDs.push(generatedGameID);
		} else {
			console.log("Assigned Game ID doc snap does not exist");
			return;
		}

		// if (gameIDs.Unassigned.length === 0) {
		// 	console.log("No games to assign.");
		// 	setGameState("No available game rooms to assign.");
		// 	return;
		// }

		const assignedRef = doc(fdb, 'GameIDs', 'Assigned');

		await updateDoc(assignedRef, { GameIDs: gameIDs });

		return generatedGameID;
	}

	function createNewGameID() {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		var charactersLength = characters.length;
		for (var i = 0; i < 4; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}

		console.log(result);
		return result;
	}

	return (
		<div className="full-page-container center-div base-text flex-column">
			<div className='game-title'>
				OTHELLO
			</div>
			<div className='landing-page-container'>
				{
					loading ? <div className='loading-text'>Loading Games...</div> :
						<div>
							<div>
								<div className='lobbies-text'>Lobbies</div>
								{games.length === 0 ? <div className='no-lobbies'>No existing lobbies.</div> : games.map((game) => (
									<div className='lobby-info' key={game.GameID}>
										<div className='lobby-code'>{game.GameID}</div>
										<div className='text-input'>{game.Status}</div>
									</div>
								))}
							</div>
							<div>
								{
									gameState && <label>{gameState}</label>
								}
								<form onSubmit={onSubmit} className="flex-column">
									<input className='text-input' type="text" name="game_id" value={gameCode} onChange={codeChangeHandler} autoComplete="off" placeholder="GameID" required />
									<input className='text-input' type="text" name="username" value={userName} onChange={e => setUserName(e.target.value)} autoComplete="off" placeholder="Username" required />
									<button className="clickable base-button base-text create-bt" type="submit">Join Game</button>
								</form>
								<div className='flex-center flex-col create-bt-container'>
									<button className="clickable base-button base-text create-bt" onClick={onCreateNewGame}>Create Online Game</button>
									<button className="clickable base-button base-text create-bt" onClick={createLocalGame}>Create Local Game</button>
								</div>
							</div>
						</div>
				}
			</div>
		</div>
	);
}

export default LandingPage;
