import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import LandingPage from "./LandingPage/LandingPage";
import GamePage from "./GamePage/GamePage";
import OthelloLocal from "./Local/OthelloLocal";

function App() {
   
    return (
        <Router>
        <div>
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Routes>
                <Route path="/" exact element={<LandingPage />}/>
                <Route path="/local" exact element={<OthelloLocal />}/>
                <Route path="/*" element={<GamePage />}/>
            </Routes>
        </div>
        </Router>
    );
}

export default App;