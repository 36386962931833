import { useState } from 'react';
import './OthelloMultiplayer.css';

function OthelloMultiplayer({ currentPlayer, playerColour, whitePoints, blackPoints, onPlayerMoved, trueBoard, gameCode, players, playerId, isHost, userName, handleEndGame, setTrueBoard, setWhitePoints, setBlackPoints }) {
	const [hoverBoard, setHoverBoard] = useState(trueBoard);
	const [whiteGain, setWhiteGain] = useState("+0");
	const [blackGain, setBlackGain] = useState("+0");
	const [infoMessage, setInfoMessage] = useState("");

	function clickCell(cellVal, cellX, cellY) {
		if (currentPlayer !== playerColour) {
			setInfoMessage("Not your turn!");
			return;
		}

		setInfoMessage("");
		setWhiteGain("+0");
		setBlackGain("+0");
		var validationResults = validatePlacementAndGetTilesToFlip(cellX, cellY);
		var canPlaceTile = validationResults[0];
		if (canPlaceTile) {
			console.log(cellX + ", " + cellY + ": " + cellVal);

			var tempBoard = trueBoard.slice();
			setHoverBoard(tempBoard);
			flipTiles(validationResults[1], cellX, cellY);

			onPlayerMoved();
			calculatePoints();
		}
	}

	function calculatePoints() {
		var white = 0;
		var black = 0;

		for (var x = 0; x < trueBoard.length; x++) {
			for (var y = 0; y < trueBoard[x].length; y++) {
				if (trueBoard[x][y] == "W") { console.log(x + ", " + y + " is white."); white++; }
				else if (trueBoard[x][y] == "B") black++;
			}
		}

		setWhitePoints(white);
		setBlackPoints(black);
	}

	function flipTiles(tilesToFlip, cellX, cellY) {
		console.log("Flipping tiles: " + tilesToFlip.join(", "));
		var tempBoard = trueBoard.slice();
		tempBoard[cellX][cellY] = playerColour;

		for (var i = 0; i < tilesToFlip.length; i++) {
			for (var x = 0; x < tilesToFlip[i].length; x++) {
				var tile = tilesToFlip[i][x];
				if (tile.length === 2) {
					tempBoard[tile[0]][tile[1]] = playerColour;
				}
			}
		}

		setTrueBoard(tempBoard);
		setHoverBoard(tempBoard);
	}

	function highlightTiles(canPlaceTile, cellRow, cellCol, tilesToHighlight, removeHighlight) {
		console.log("Highlighting tiles: " + tilesToHighlight.join(", "));
		var tempBoard = JSON.parse(JSON.stringify(hoverBoard));
		var totalGain = 0;

		for (var i = 0; i < tilesToHighlight.length; i++) {
			for (var x = 0; x < tilesToHighlight[i].length; x++) {
				var tile = tilesToHighlight[i][x];
				if (tile.length === 2) {
					if (removeHighlight) {
						tempBoard[tile[0]][tile[1]] = trueBoard[tile[0]][tile[1]];
					}
					else {
						tempBoard[tile[0]][tile[1]] = "HiG";
						totalGain++;
					}
				}
			}
		}

		if (removeHighlight) {
			tempBoard[cellRow][cellCol] = "0";
		}
		else {
			if (canPlaceTile) {
				setInfoMessage("");
				tempBoard[cellRow][cellCol] = "HovG" + playerColour;
			}
			else {
				tempBoard[cellRow][cellCol] = "HovR" + playerColour;
			}
		}

		setHoverBoard(tempBoard);

		if (!removeHighlight) {
			if (playerColour === "B") {
				setBlackGain("+" + (totalGain + 1));
				setWhiteGain("-" + (totalGain));
			}
			else {
				setBlackGain("-" + (totalGain));
				setWhiteGain("+" + (totalGain + 1));
			}
		}
	}

	function validatePlacementAndGetTilesToFlip(cellY, cellX) {
		if (trueBoard[cellY][cellX] == "W" || trueBoard[cellY][cellX] == "B") {
			console.log("Tile already on position.");
			setInfoMessage("There is already a tile in this position.");
			return false;
		}

		// Check top
		var placeTile = false;
		var validNeighbour = false;
		var allTilesForFlip = [];

		if (cellY != 0) {
			if (isOpponentTile(cellY - 1, cellX)) {
				console.log("Opponent Tile above: " + (cellY - 1) + ", " + cellX);
				var tilesToFlip = getTilesToFlip(cellY, cellX, -1, 0, 0, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 0 && isOpponentTile(cellY - 1, cellX - 1)) {
				console.log("Opponent Tile top left: " + (cellY - 1) + ", " + (cellX - 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, -1, -1, 0, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 7 && isOpponentTile(cellY - 1, cellX + 1)) {
				console.log("Opponent Tile top right: " + (cellY - 1) + ", " + (cellX + 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, -1, 1, 0, 7);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}
		}
		// Check right
		if (cellX != 7 && isOpponentTile(cellY, cellX + 1)) {
			console.log("Oppononent tile to the right: " + cellY + ", " + (cellX + 1));
			var tilesToFlip = getTilesToFlip(cellY, cellX, 0, 1, 0, 7);
			placeTile = placeTile || tilesToFlip.length > 0;
			allTilesForFlip.push(tilesToFlip);
			validNeighbour = true;
		}

		// Check bottom
		if (cellY != 7) {
			if (isOpponentTile(cellY + 1, cellX)) {
				console.log("Opponent Tile below: " + (cellY + 1) + ", " + cellX);
				var tilesToFlip = getTilesToFlip(cellY, cellX, 1, 0, 7, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 0 && isOpponentTile(cellY + 1, cellX - 1)) {
				console.log("Opponent Tile bottom left: " + (cellY + 1) + ", " + (cellX - 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, 1, -1, 7, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 7 && isOpponentTile(cellY + 1, cellX + 1)) {
				console.log("Opponent Tile bottom right: " + (cellY + 1) + ", " + (cellX + 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, 1, 1, 7, 7);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}
		}

		// Check left
		if (cellX != 0 && isOpponentTile(cellY, cellX - 1)) {
			console.log("Oppononent tile to the left: " + cellY + ", " + (cellX - 1));
			var tilesToFlip = getTilesToFlip(cellY, cellX, 0, -1, 0, 0);
			placeTile = placeTile || tilesToFlip.length > 0;
			allTilesForFlip.push(tilesToFlip);
			validNeighbour = true;
		}

		if (!placeTile) {
			if (validNeighbour) {
				setInfoMessage("No flippable tiles.")
			}
			else {
				console.log("Cannot place tile: no adjacent opponent tiles!");
				console.log(allTilesForFlip);
				setInfoMessage("Cannot place tile here as there are no adjacent opponent tiles.");
			}
		}

		return [placeTile, allTilesForFlip];
	}

	function getTilesToFlip(cellX, cellY, stepX, stepY, limitX, limitY) {
		var tilesToFlip = [];
		var performFlip = false;

		while ((cellX != limitX || stepX === 0) && (cellY != limitY || stepY === 0)) {
			if (cellX != limitX) {
				cellX += stepX;
			}

			if (cellY != limitY) {
				cellY += stepY;
			}

			if (isOpponentTile(cellX, cellY)) {
				tilesToFlip.push([cellX, cellY]);
			}
			else if (isAllyTile(cellX, cellY)) {
				performFlip = true;
				break;
			}
			else {
				tilesToFlip = [];
				break;
			}
		}

		console.log("Tiles to flip: " + tilesToFlip.join(", "));
		return performFlip ? tilesToFlip : [];
	}

	function isOpponentTile(cellX, cellY) {
		console.log("Checking: " + cellX + ", " + cellY);
		if (playerColour == "W" && trueBoard[cellX][cellY] == "B") return true;
		if (playerColour == "B" && trueBoard[cellX][cellY] == "W") return true;
		console.log(trueBoard[cellX][cellY] + "is not an opponent tile.");
		return false;
	}

	function isAllyTile(cellX, cellY) {
		console.log("Checking: " + cellX + ", " + cellY);
		if (playerColour == "W" && trueBoard[cellX][cellY] == "W") return true;
		if (playerColour == "B" && trueBoard[cellX][cellY] == "B") return true;
		console.log(trueBoard[cellX][cellY] + "is not an ally tile.");
		return false;
	}

	// 2 rules for placing a tile
	// 	1: Must be adjacent to opponent tile
	//		
	// 	2: Must flip an opponent tile 

	function handleHover(cellRow, cellCol) {
		if (players.length !== 2) {
			setInfoMessage("Waiting for another player...");
			return;
		}

		if (trueBoard[cellRow][cellCol] === "W" || trueBoard[cellRow][cellCol] === "B") {
			setInfoMessage("There is already a tile in this position");
		}

		var validationResults = validatePlacementAndGetTilesToFlip(cellRow, cellCol);
		var canPlaceTile = validationResults[0];
		var flippableTiles = validationResults[1];
		console.log("Flippable tiles: " + flippableTiles.join(", "));
		highlightTiles(canPlaceTile, cellRow, cellCol, flippableTiles, false);
	}

	function handleMouseLeave(cellRow, cellCol) {
		setBlackGain("+0");
		setWhiteGain("+0");
		if (trueBoard[cellRow][cellCol] === "W" || trueBoard[cellRow][cellCol] === "B") return;
		var flippableTiles = validatePlacementAndGetTilesToFlip(cellRow, cellCol)[1];
		highlightTiles(true, cellRow, cellCol, flippableTiles, true);
		setInfoMessage("");
	}

	function copyGameCode() {
		navigator.clipboard.writeText(`https://othello.vbi.dev/${gameCode}`)
	}

	return (
		<div className="App">
			<div className='main-elements-container'>
				<div className="flex-col">
					{
						players.length === 2 ?
							<div className="turn-container flex-row flex-center justify-space-between">
								<div className={"tile tile-large ten-p " + (currentPlayer === "W" ? "tile-white" : "tile-black")}></div>
								<div className="ninety-p turn-text">{`${currentPlayer === "B" ? playerId === 1 ? "Your" : "Black's" : playerId === 2 ? "Your" : "White's"} Turn`}</div>
							</div>
							: <div>
								<div className="flex-center waiting-text">Waiting for another player...</div>
							</div>
					}
					<div className="flex-row justify-space-between">
						<div className="flex-row flex-center pre-wrap">
							<div className="tile tile-large tile-white"></div>
							<h1>{` : ${whitePoints}`}</h1>
						</div>
						<div className="flex-row flex-center pre-wrap">
							<div className="tile tile-large tile-black"></div>
							<h1>{` : ${blackPoints}`}</h1>
						</div>
					</div>
					<div className="table-container">
						<div className="board">
							<div className="board-plate">
								{trueBoard.map((row, i) =>
									<div id={i} key={i} className="table-row">{row.map((cell, k) =>
										<div
											id={i + "" + k}
											key={i + ", " + k}
											onClick={() => clickCell(cell, i, k)}
											className={"table-cell " + (hoverBoard[i][k] === "HiG" ? "highlight-green" : hoverBoard[i][k] === "HovRB" || hoverBoard[i][k] === "HovRW" ? "highlight-red" : "")}
											onMouseOver={() => handleHover(i, k)}
											onMouseLeave={() => handleMouseLeave(i, k)}>
											<div
												className={"tile " + (
													trueBoard[i][k] == "B" ?
														"tile-black"
														: trueBoard[i][k] == "W" ?
															"tile-white"
															: hoverBoard[i][k] == "HovGB" || hoverBoard[i][k] === "HovRB" ?
																"tile-black hover"
																: hoverBoard[i][k] == "HovGW" || hoverBoard[i][k] === "HovRW" ?
																	"tile-white hover"
																	:
																	"")}>
											</div>
										</div>)}
									</div>)}
							</div>
						</div>
					</div>
					<div className="flex-row flex-center">
						<div className="flex-row justify-space-between gain-container">
							{<div className="flex-row">
								<div className='flex-col'>
									<div className='flex-row text-title'>
										<div>Black:</div>
										<div className={blackGain[0] === '-' ? "text-red" : "text-green"}>{blackGain}</div>
									</div>
									<div className='player-container player-black'>
										{`${players[0] === undefined ? 'Waiting...' : players[0]} ${players[0] === userName ? '(me)' : ''}`}
									</div>
								</div>
							</div>}
							{<div className="flex-row">
								<div className='flex-col align-items-right'>
									<div className='flex-row text-title'>
										<div>White:</div>
										<div className={whiteGain[0] === '-' ? "text-red" : "text-green"}>{whiteGain}</div>
									</div>
									<div className='player-container player-white'>
										{`${players[1] === undefined ? 'Waiting...' : players[1]} ${players.length === 2 && players[1] === userName ? '(me)' : ''}`}
									</div>
								</div>
							</div>}
						</div>
						{infoMessage.length > 0 && <div className={"info-message-container " + (infoMessage !== "" ? "flex-center" : "")}>
							{infoMessage !== "" && <div>{infoMessage}</div>}
						</div>}
					</div>
				</div>
			</div>

			<div className='flex-center flex-col'>
				<div className='flex-center flex-col'>
					<div className='game-code-title'>Game Code:</div>
					<div className='game-code' onClick={copyGameCode}>📋 {gameCode}</div>
				</div>
				{
					playerColour !== 'S' && <div className="game-code end-game-bt" onClick={handleEndGame}>End Game</div>
				}
			</div>
		</div>
	);
}



export default OthelloMultiplayer;
