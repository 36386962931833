import { useState } from 'react';
import './OthelloLocal.css';

function OthelloLocal() {
	var y = Array(8).fill(0).map(y => Array(8).fill(0));
	var x = Array(8).fill(0).map(x => Array(8).fill(0));
	x[3][3] = "W";
	x[3][4] = "B";
	x[4][3] = "B";
	x[4][4] = "W";

	const [trueBoard, setTrueBoard] = useState(x);
	const [hoverBoard, setHoverBoard] = useState(x);
	const [currentPlayer, setCurrentPlayer] = useState("B");
	const [whitePoints, setWhitePoints] = useState(2);
	const [blackPoints, setBlackPoints] = useState(2);
	const [whiteGain, setWhiteGain] = useState("+0");
	const [blackGain, setBlackGain] = useState("+0");
	const [infoMessage, setInfoMessage] = useState("");

	function clickCell(cellVal, cellX, cellY) {
		setInfoMessage("");
		setWhiteGain("+0");
		setBlackGain("+0");
		var validationResults = validatePlacementAndGetTilesToFlip(cellX, cellY);
		var canPlaceTile = validationResults[0];
		if (canPlaceTile) {
			console.log(cellX + ", " + cellY + ": " + cellVal);

			var tempBoard = trueBoard.slice();
			tempBoard[cellX][cellY] = currentPlayer;
			setTrueBoard(tempBoard);
			setHoverBoard(tempBoard);

			flipTiles(validationResults[1]);

			if (currentPlayer == "W") {
				setCurrentPlayer("B");
			}
			else {
				setCurrentPlayer("W");
			}

			calculatePoints();
		}
	}

	function calculatePoints() {
		var white = 0;
		var black = 0;

		for (var x = 0; x < trueBoard.length; x++) {
			for (var y = 0; y < trueBoard[x].length; y++) {
				if (trueBoard[x][y] == "W") { console.log(x + ", " + y + " is white."); white++; }
				else if (trueBoard[x][y] == "B") black++;
			}
		}

		setWhitePoints(white);
		setBlackPoints(black);
	}

	function flipTiles(tilesToFlip) {
		console.log("Flipping tiles: " + tilesToFlip.join(", "));
		var tempBoard = trueBoard.slice();

		for (var i = 0; i < tilesToFlip.length; i++) {
			for (var x = 0; x < tilesToFlip[i].length; x++) {
				var tile = tilesToFlip[i][x];
				if (tile.length === 2) {
					tempBoard[tile[0]][tile[1]] = currentPlayer;
				}
			}
		}

		setTrueBoard(tempBoard);
		setHoverBoard(tempBoard);
	}

	function highlightTiles(canPlaceTile, cellRow, cellCol, tilesToHighlight, removeHighlight) {
		console.log("Highlighting tiles: " + tilesToHighlight.join(", "));
		var tempBoard = JSON.parse(JSON.stringify(hoverBoard));
		var totalGain = 0;

		for (var i = 0; i < tilesToHighlight.length; i++) {
			for (var x = 0; x < tilesToHighlight[i].length; x++) {
				var tile = tilesToHighlight[i][x];
				if (tile.length === 2) {
					if (removeHighlight) {
						tempBoard[tile[0]][tile[1]] = trueBoard[tile[0]][tile[1]];
					}
					else {
						tempBoard[tile[0]][tile[1]] = "HiG";
						totalGain++;
					}
				}
			}
		}

		if (removeHighlight) {
			tempBoard[cellRow][cellCol] = "0";
		}
		else {
			if (canPlaceTile) {
				setInfoMessage("");
				tempBoard[cellRow][cellCol] = "HovG" + currentPlayer;
			}
			else {
				tempBoard[cellRow][cellCol] = "HovR" + currentPlayer;
			}
		}

		setHoverBoard(tempBoard);

		if (!removeHighlight) {
			if (currentPlayer === "B") {
				setBlackGain("+" + (totalGain + 1));
				setWhiteGain("-" + (totalGain));
			}
			else {
				setBlackGain("-" + (totalGain));
				setWhiteGain("+" + (totalGain + 1));
			}
		}
	}

	function validatePlacementAndGetTilesToFlip(cellY, cellX) {
		if (trueBoard[cellY][cellX] == "W" || trueBoard[cellY][cellX] == "B") {
			console.log("Tile already on position.");
			setInfoMessage("There is already a tile in this position.");
			return false;
		}

		// Check top
		var placeTile = false;
		var validNeighbour = false;
		var allTilesForFlip = [];

		if (cellY != 0) {
			if (isOpponentTile(cellY - 1, cellX)) {
				console.log("Opponent Tile above: " + (cellY - 1) + ", " + cellX);
				var tilesToFlip = getTilesToFlip(cellY, cellX, -1, 0, 0, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 0 && isOpponentTile(cellY - 1, cellX - 1)) {
				console.log("Opponent Tile top left: " + (cellY - 1) + ", " + (cellX - 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, -1, -1, 0, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 7 && isOpponentTile(cellY - 1, cellX + 1)) {
				console.log("Opponent Tile top right: " + (cellY - 1) + ", " + (cellX + 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, -1, 1, 0, 7);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}
		}
		// Check right
		if (cellX != 7 && isOpponentTile(cellY, cellX + 1)) {
			console.log("Oppononent tile to the right: " + cellY + ", " + (cellX + 1));
			var tilesToFlip = getTilesToFlip(cellY, cellX, 0, 1, 0, 7);
			placeTile = placeTile || tilesToFlip.length > 0;
			allTilesForFlip.push(tilesToFlip);
			validNeighbour = true;
		}

		// Check bottom
		if (cellY != 7) {
			if (isOpponentTile(cellY + 1, cellX)) {
				console.log("Opponent Tile below: " + (cellY + 1) + ", " + cellX);
				var tilesToFlip = getTilesToFlip(cellY, cellX, 1, 0, 7, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 0 && isOpponentTile(cellY + 1, cellX - 1)) {
				console.log("Opponent Tile bottom left: " + (cellY + 1) + ", " + (cellX - 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, 1, -1, 7, 0);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}

			if (cellX != 7 && isOpponentTile(cellY + 1, cellX + 1)) {
				console.log("Opponent Tile bottom right: " + (cellY + 1) + ", " + (cellX + 1));
				var tilesToFlip = getTilesToFlip(cellY, cellX, 1, 1, 7, 7);
				placeTile = placeTile || tilesToFlip.length > 0;
				allTilesForFlip.push(tilesToFlip);
				validNeighbour = true;
			}
		}

		// Check left
		if (cellX != 0 && isOpponentTile(cellY, cellX - 1)) {
			console.log("Oppononent tile to the left: " + cellY + ", " + (cellX - 1));
			var tilesToFlip = getTilesToFlip(cellY, cellX, 0, -1, 0, 0);
			placeTile = placeTile || tilesToFlip.length > 0;
			allTilesForFlip.push(tilesToFlip);
			validNeighbour = true;
		}

		if (!placeTile) {
			if (validNeighbour) {
				setInfoMessage("No flippable tiles.")
			}
			else {
				console.log("Cannot place tile: no adjacent opponent tiles!");
				console.log(allTilesForFlip);
				setInfoMessage("Cannot place tile here as there are no adjacent opponent tiles.");
			}
		}

		return [placeTile, allTilesForFlip];
	}

	function getTilesToFlip(cellX, cellY, stepX, stepY, limitX, limitY) {
		var tilesToFlip = [];
		var performFlip = false;

		while ((cellX != limitX || stepX === 0) && (cellY != limitY || stepY === 0)) {
			if (cellX != limitX) {
				cellX += stepX;
			}

			if (cellY != limitY) {
				cellY += stepY;
			}

			if (isOpponentTile(cellX, cellY)) {
				tilesToFlip.push([cellX, cellY]);
			}
			else if (isAllyTile(cellX, cellY)) {
				performFlip = true;
				break;
			}
			else {
				tilesToFlip = [];
				break;
			}
		}

		console.log("Tiles to flip: " + tilesToFlip.join(", "));
		return performFlip ? tilesToFlip : [];
	}

	function isOpponentTile(cellX, cellY) {
		console.log("Checking: " + cellX + ", " + cellY);
		if (currentPlayer == "W" && trueBoard[cellX][cellY] == "B") return true;
		if (currentPlayer == "B" && trueBoard[cellX][cellY] == "W") return true;
		console.log(trueBoard[cellX][cellY] + "is not an opponent tile.");
		return false;
	}

	function isAllyTile(cellX, cellY) {
		console.log("Checking: " + cellX + ", " + cellY);
		if (currentPlayer == "W" && trueBoard[cellX][cellY] == "W") return true;
		if (currentPlayer == "B" && trueBoard[cellX][cellY] == "B") return true;
		console.log(trueBoard[cellX][cellY] + "is not an ally tile.");
		return false;
	}

	// 2 rules for placing a tile
	// 	1: Must be adjacent to opponent tile
	//		
	// 	2: Must flip an opponent tile 

	function handleHover(cellRow, cellCol) {
		if (trueBoard[cellRow][cellCol] === "W" || trueBoard[cellRow][cellCol] === "B") {
			setInfoMessage("There is already a tile in this position");
		}
		var validationResults = validatePlacementAndGetTilesToFlip(cellRow, cellCol);
		var canPlaceTile = validationResults[0];
		var flippableTiles = validationResults[1];
		console.log("Flippable tiles: " + flippableTiles.join(", "));
		highlightTiles(canPlaceTile, cellRow, cellCol, flippableTiles, false);
	}

	function handleMouseLeave(cellRow, cellCol) {
		setBlackGain("+0");
		setWhiteGain("+0");
		if (trueBoard[cellRow][cellCol] === "W" || trueBoard[cellRow][cellCol] === "B") return;
		var flippableTiles = validatePlacementAndGetTilesToFlip(cellRow, cellCol)[1];
		highlightTiles(true, cellRow, cellCol, flippableTiles, true);
		setInfoMessage("");
	}

	return (
		<div className="App">
			<div className="flex-col">
				<div className="turn-container flex-row flex-center justify-space-between">
					<div className={"tile tile-large ten-p " + (currentPlayer === "W" ? "tile-white" : "tile-black")}></div>
					<h1 className="ninety-p">{`${currentPlayer === "B" ? "Black" : "White"}'s Turn`}</h1>
				</div>
				<div className="flex-row justify-space-between">
					<div className="flex-row flex-center pre-wrap">
						<div className="tile tile-large tile-white"></div>
						<h1>{` : ${whitePoints}`}</h1>
					</div>
					<div className="flex-row flex-center pre-wrap">
						<div className="tile tile-large tile-black"></div>
						<h1>{` : ${blackPoints}`}</h1>
					</div>
				</div>
				<div className="table-container">
					<div className="board">
						<div className="board-plate">
							{trueBoard.map((row, i) =>
								<div id={i} key={i} className="table-row">{row.map((cell, k) =>
									<div
										id={i + "" + k}
										key={i + ", " + k}
										onClick={() => clickCell(cell, i, k)}
										className={"table-cell " + (hoverBoard[i][k] === "HiG" ? "highlight-green" : hoverBoard[i][k] === "HovRB" || hoverBoard[i][k] === "HovRW" ? "highlight-red" : "")}
										onMouseOver={() => handleHover(i, k)}
										onMouseLeave={() => handleMouseLeave(i, k)}>
										<div
											className={"tile " + (
												trueBoard[i][k] == "B" ?
													"tile-black"
													: trueBoard[i][k] == "W" ?
														"tile-white"
														: hoverBoard[i][k] == "HovGB" || hoverBoard[i][k] === "HovRB" ?
															"tile-black hover"
															: hoverBoard[i][k] == "HovGW" || hoverBoard[i][k] === "HovRW" ?
																"tile-white hover"
																:
																"")}>
										</div>
									</div>)}
								</div>)}
						</div>
					</div>
				</div>
				<div className="flex-row justify-space-between">
					{infoMessage === "" && <div className="flex-row">
						<h1>{infoMessage.length === 0 ? "Black: " : ""}</h1>
						<h1 className={blackGain[0] === '-' ? "text-red" : "text-green"}>{infoMessage.length === 0 ? blackGain : ""}</h1>
					</div>}
					{infoMessage === "" && <div className="flex-row">
						<h1>{infoMessage.length === 0 ? "White: " : ""}</h1>
						<h1 className={whiteGain[0] === '-' ? "text-red" : "text-green"}>{infoMessage.length === 0 ? whiteGain : ""}</h1>
					</div>}
				</div>
			</div>
			<div className={"info-message-container-local " + (infoMessage !== "" ? "flex-center" : "")}>
				{infoMessage !== "" && <h1>{infoMessage}</h1>}
			</div>
		</div>
	);
}

export default OthelloLocal;
