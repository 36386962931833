import { ReactSession } from 'react-client-session';

export function setGameDetails(gameID, userName) {
    ReactSession.setStoreType("localStorage");
    ReactSession.set(gameID, userName);
}

export function retrieveGameDetails(gameID) {
    ReactSession.setStoreType("localStorage");
    return ReactSession.get(gameID);
}

export function deleteGameID() {
    ReactSession.setStoreType("localStorage");
    return ReactSession.remove('game_id');
}

export function setUserId(userId) {
    ReactSession.setStoreType("localStorage");
    return ReactSession.set('user_id', userId);
}

export function retrieveUserId() {
    ReactSession.setStoreType("localStorage");
    return ReactSession.get('user_id');
}

export function deleteUserId() {
    ReactSession.setStoreType("localStorage");
    return ReactSession.remove('user_id');
}

export function deleteStorage() {
    deleteGameID();
    deleteUserId();
}